var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
__vue__options__.staticRenderFns = [function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"text-align":"right"}},[_c('a',{attrs:{"href":"#/login"}},[_vm._v("Login!")])]),_vm._v(" "),_c('div',{staticStyle:{"font-size":"xx-large"}},[_c('strong',[_vm._v("Welcome,")])]),_vm._v(" "),_c('div',{staticStyle:{"font-size":"xx-large"}},[_vm._v(" This Application is an attempt to \n        create a solution to the increasing rate of insecurity.\n        "),_c('p',[_vm._v(" This project is intended to be open sourced with the hope \n            that some team somewhere would find it worthy to improve on\n            or to even recreate.\n        ")]),_vm._v(" "),_c('div',{staticStyle:{"margin-top":"50px"}},[_vm._v("\n             The Mobile App's home screen boosts of visible buttons with descriptions\n             like \"Robbery, Fire and Accident\".\n             "),_c('p',[_vm._v("When a user hits any of those buttons, the respective alert is sent to the\n             Response unit. Alert goes out in the following form.")]),_vm._v(" "),_c('ol',[_c('li',[_vm._v(" Call to Response Agency ")]),_vm._v(" "),_c('li',[_vm._v(" Message to Agency ")]),_vm._v(" "),_c('li',[_vm._v(" Message to Emergency Contact ")])]),_vm._v("\n             Users are expected to register (though not necessarily), but when they register\n             the Web API is able to send message to emergency contacts.\n         ")])]),_vm._v(" "),_c('div',{staticStyle:{"color":"red"}},[_vm._v("\n        *** Its primarily a mobile Application project, but has web components e.g. the API\n    ")])])}]
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-30e95e9a", __vue__options__)
  } else {
    hotAPI.reload("data-v-30e95e9a", __vue__options__)
  }
})()}