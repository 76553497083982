// Brunch automatically concatenates all files in your
// watched paths. Those paths can be configured at
// config.paths.watched in "brunch-config.js".
//
// However, those files will only be executed if
// explicitly imported. The only exception are files
// in vendor, which are never wrapped in imports and
// therefore are always executed.

// Import dependencies
//
// If you no longer want to use a dependency, remember
// to also remove its path from "config.paths.watched".

// Import local files
//
// Local files can be imported directly using relative
// paths "./socket" or full ones "web/static/js/socket".

// import socket from "./socket"
import "phoenix_html"
// import Vue from "vue";
// import VueRouter from "vue-router";
// import axios from "axios";

// import login from "../components/login.vue";
// import signup from "../components/signup.vue";
// import first from "../components/main.vue";
// import dashboard from "../components/dashboard.vue";

// Vue.use(VueRouter);

// Vue.component("login", login);
// Vue.component("signup", signup);
// Vue.component("first", first);
// Vue.component("dashboard", dashboard);
// Vue.use(require('vue-moment'));

// var router = new VueRouter({
//     routes: [
//         // { path: '/', component: first},
//         { path: '/', component: login},
//         { path: '/signup', component: signup},
//         { path: '/dashboard', component: dashboard},
//         { path: '*', component: login}
//     ]
// })

// new Vue({
//     router
// }).$mount("#mx-app");
