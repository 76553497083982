;(function(){
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import auth from "../js/auth";
import moment from 'moment';
const historyAPI = "/api/report/id";
export default {
    mounted () {
        this.getHistory()
    },
    data: function () {
      return{
          results: {
              history:[]
          },
          visible: false
      }
    },
    methods: {
        getHistory() {
            axios
                .get(historyAPI, { headers: auth.getAuthHeader()})
                .then((response) => {
                    this.results = response.data.history;
                    this.visible = true;
            }).catch( error => { console.log(error); });
        },
        logout() {
            auth.logout();
        }
    },
    filters: {
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('MM/DD/YYYY hh:mm');
            }
        }
    }
};

})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"text-align":"right","cursor":"pointer"},on:{"click":function($event){_vm.logout()}}},[_vm._v("Logout!")]),_vm._v(" "),(_vm.visible)?_c('div'):_vm._e()])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-5c2f5e03", __vue__options__)
  } else {
    hotAPI.reload("data-v-5c2f5e03", __vue__options__)
  }
})()}