import axios from "axios";
import {Socket} from "phoenix";

export default {
    user: { username: "" }, 
    socket: null,
    do_login: function(response, creds, context, redirect) {
      this.user.username = creds.username;
      window.localStorage.setItem('token-' + this.user.username, response.data.token);
      window.localStorage.setItem('username', this.user.username);
      window.localStorage.setItem('name', response.data.name);
      if (redirect) {
        context.$router.push({path: redirect});
      }
    },
    login: function (context, creds, redirect) {
        axios.post("/api/sessions/login", creds)
        .then(response => {
          this.do_login(response, creds, context, redirect);
        })
        .catch(error => {
          alertify.error('Error signing in, please check your credentials.');
          console.log(error);
        });
      },
      signup: function (context, creds, redirect) {
        axios.post("/api/session/signup", creds)
        .then(response => {
          this.do_login(response, creds, context, redirect);
        })
        .catch(error => {
          alertify.error('Error signing up, please fill all fields.');
          console.log(error);
        });
      },
      logout: function(context, options) {
        axios.delete("/api/session", { headers: this.getAuthHeader()})
          .then(response => {
            window.localStorage.removeItem('token-'+ this.getUserName());
            this.user.username = "";
            this.socket = null;
            window.location.reload();
          }).catch(error => {
            console.log(error);
          });
      },
      authenticated: function() {
        const jwt = window.localStorage.getItem('token-'+ this.getUserName());
        return !!jwt;
      },
      getAuthHeader: function() {
         return {'Authorization': window.localStorage.getItem('token-'+ this.getUserName())};
      },
      getUserName: function() {
        if (!this.user.username) {
          this.user.username = window.localStorage.getItem('username');
        }
        return this.user.username;
      },
      getName: function() {
        if (!this.user.name) {
          this.user.name = window.localStorage.getItem('name');
        }
        return this.user.name;
      }
  }