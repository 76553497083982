;(function(){
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import auth from "../js/auth";

export default {
    data: function () {
        return {
            username: "fred",
            password: "parool"
        }
    },
    methods: {
        login: function() {
            auth.login(this, {username: this.username, password: this.password}, "/dashboard");
        }
    }
}

})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"well col-sm-offset-3 col-sm-6"},[_c('h2',[_vm._v("Please log in")]),_vm._v(" "),_c('div',[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label col-sm-3",attrs:{"for":"pickup_address"}},[_vm._v("USERNAME")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"form-control",attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.username=$event.target.value}}})]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label col-sm-3",attrs:{"for":"pickup_address"}},[_vm._v("PASSWORD")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",attrs:{"type":"password","id":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}})]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-default col-sm-12",attrs:{"id":"login"},on:{"click":_vm.login}},[_vm._v("LOGIN")])])])])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-d1421850", __vue__options__)
  } else {
    hotAPI.reload("data-v-d1421850", __vue__options__)
  }
})()}